<template>
  <div class="sf-product-quick-switcher-wrapper">
    <MModalProductQuickView
      v-if="isProductQuickSwitcherOpen"
      class="sf-product-quick-switcher"
      :is-visible="isProductQuickSwitcherOpen"
      :modal-data="getModalData('product-quick-view')"
      @close="closeProductQuickModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import MModalProductQuickView from '~/theme/components/molecules/modals/m-modal-product-quick-view';

export default {
  components: {
    MModalProductQuickView
  },
  mixins: [onEscapePress],
  computed: {
    ...mapState({
      isProductQuickSwitcherOpen: state => state.ui.productQuickSwitcher
    })
  },
  watch: {
    isProductQuickSwitcherOpen: {
      immediate: true,
      handler (val) {
        !val && this.closeProductQuickModal()
      }
    }
  },
  methods: {
    onEscapePress () {
      this.closeProductQuickModal();
    },
    closeProductQuickModal () {
      this.$store.dispatch('ui/toggleProductQuickSwitcher', false)
    },
    getModalData (name) {
      return {
        name: name
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.default-layout {
  .sf-product-quick-switcher-wrapper {
    @include for-mobile {
      .sticky-bottom {
        display: none;
      }
    }
    .sf-modal__container {
      h1 {
        margin: 0;
      }
      width: 650px;
      border-radius: var(--spacer-10);
      .sf-modal__content {
        padding: var(--spacer-25);
      }
      .o-product-details__rating {
        .product__rating {
          padding: var(--spacer-10) var(--spacer-20);
        }
      }
      .m-product-options-configurable__header {
        margin: 0;
        text-align: left;
      }
      .sf-select {
        padding: 5px 0 0 0;
        height: auto;
      }
      .product__add-to-cart {
        margin: 0;
      }
      .product__description--desktop {
        p {
          text-align: left;
          margin: 0 0 5px 0;
        }
      }
      .m-product-gallery {
        width: 160px;
        @include for-mobile {
          margin: auto;
        }
        .m-product-gallery-thumbs-wrapper {
          display: none !important;
        }
        .m-product-gallery__bullets-wrapper {
          flex-wrap: wrap;
          display: flex !important;
          .m-product-gallery__bullet {
            width: 8px;
            height: 8px;
            margin: 2px;
          }
        }
        .badges__wrapper {
          padding: 0;
          right: 0;
          margin: 0;
          width: auto;
          @include for-mobile {
            width: 100%;
          }
          .badges__badge {
            padding: 5px;
            font-size: 12px;
            margin-left: var(--spacer-sm);
            margin-right: 0;
          }
        }
        .glide__slide {
          min-width: 160px;
          width: 160px;
          max-width: 160px;
          @include for-mobile {
            min-width: auto;
            width: 100%;
            max-width: 100%;
          }
        }
        .sf-image.has-size>img {
          width: auto;
          max-width: 100%;
          min-width: inherit;
        }
        .sf-gallery__stage {
          width: 160px;
          order: -1;
        }
      }
      .sf-product-card {
        &:last-child {
          padding-bottom: var(--spacer-15);
        }
      }
      .sf-gallery {
        min-height: inherit;
        display: flex;
        flex-direction: column
      }
      .product__title {
        text-align: left;
        margin-bottom: 5px;
        font-weight: var(--font-weight--medium);
      }
      .product__info {
        margin: 0;
        padding-left: var(--spacer-20);
        @include for-mobile {
          padding-left: 0;
          .m-product-short-info  {
            padding-left: 0;
          }
        }
      }
      .a-tier-prices {
        margin: 0;
        padding: var(--spacer-10) 0;
        font-size: 14px;
        &:empty {
          padding: 0 0;
        }
        @include for-desktop {
          justify-content: start;
        }
        .sf-button {
          height: auto;
        }
      }
      .sf-select__error-message {
        min-height: inherit;
      }
      @include for-mobile {
        .sf-gallery,
        .sf-gallery__stage {
          max-width: 100% !important;
          min-width: 100%;
          width: 100%;
        }
        .m-product-promotions {
          &:empty {
            margin: 0;
          }
        }
        .product__description,
        .o-product-details__mobile-add-to-cart-bottom {
          display: none;
        }
        .o-product-details,
        .product-configurable {
          display: flex;
          flex-direction: column;
        }
        .sf-modal__content {
          padding: var(--spacer-25) var(--spacer-20);
        }
        .o-product-details {
          display: flex;
          padding-bottom: var(--spacer-80);
        }
        .product__title {
          font-size: 14px;
          padding: 0;
          letter-spacing: 2px;
          word-break: break-word;
          text-align: center;
        }
        .m-product-gallery {
          width: 100%;
          float: left;
          margin-bottom: var(--spacer-20);
          .sf-button {
            top: 0;
            bottom: 0;
            margin: auto;
            .sf-icon {
              --icon-size: 1.5rem !important;
            }
          }
        }
        .product__info {
          padding-right: 0;
        }
        .m-product-short-info {
          width: 100%;
          padding-left: var(--spacer-10);
          box-sizing: border-box;
        }
        .o-product-details__rating-mobile {
          width: 44%;
          flex-wrap: wrap;
          float: left;
          padding: var(--spacer-10) 0 0 var(--spacer-10);
          box-sizing: border-box;
        }
        .product__rating {
          flex-wrap: wrap;
          .product__count {
            text-align: left;
          }
        }
        .product__add-to-cart-wrapper.smartphone-only {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: var(--spacer-20);
          background-color: #fff;
          box-sizing: border-box;
          z-index: 2;
          border-top: 1px solid #f1f1f1;
        }
        .product__info {
          clear: both;
        }
        .m-product-gallery,
        .set-tier {
          order: -1;
        }
        .sf-modal__bar {
          display: none;
        }
        .sf-button {
          display: block !important;
          z-index: 1;
        }
        width: 95%;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        bottom: auto;
        left: 0;
        z-index: var(--modal-index, 1);
        max-height: 90vh;
        overflow: auto;
        margin: auto;
      }
      .additional-info {
        display: none;
      }
    }
  }
}
</style>
