<template>
  <div class="m-modal-product-quick-view">
    <SfModal :visible="isVisible" @close="closeModal">
      <div id="product">
        <OProductDetails
          :product="getCurrentProduct"
          :product-gallery="getProductGallery"
          :product-configuration="getCurrentProductConfiguration"
          :product-custom-options="getCurrentCustomOptions"
          :product-attributes="getCustomAttributes"
          :product-stock="stock"
          :is-rendered-by-s-s-r="isRenderedBySSR"
          :is-ready-to-send-g-t-m-events="isReadyToSendGTMEvents"
        />
      </div>
    </SfModal>
  </div>
</template>

<script>
import SfModal from '~/theme/components/molecules/SfModal/SfModal.vue';
import { mapGetters } from 'vuex';
import OProductDetails from '~/theme/components/organisms/o-product-details';
import { filterChangedProduct } from '@vue-storefront/core/modules/catalog/events';

export default {
  name: 'MModalProductQuickView',
  components: {
    SfModal,
    OProductDetails
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      stock: {
        isLoading: false,
        max: 0,
        manageQuantity: true
      },
      isRenderedBySSR: false,
      isReadyToSendGTMEvents: false
    };
  },
  provide() {
    return {
      configurableOptionCallback: this.configurableOptionCallback
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCurrentProduct: 'product/getCurrentProduct',
      getProductGallery: 'product/getProductGallery',
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration',
      getOriginalProduct: 'product/getOriginalProduct',
      attributesByCode: 'attribute/attributeListByCode',
      getCurrentCustomOptions: 'product/getCurrentCustomOptions',
      cartItems: 'cart/getCartItems'
    }),
    getCustomAttributes() {
      return Object.values(this.attributesByCode)
        .filter(a => {
          return (
            a.is_visible &&
            a.is_user_defined &&
            (parseInt(a.is_visible_on_front) ||
              a.is_visible_on_front === true) &&
            this.getCurrentProduct[a.attribute_code]
          );
        })
        .sort((a, b) => {
          return a.attribute_id > b.attribute_id;
        });
    }
  },
  methods: {
    closeModal() {
      this.$emit('close', this.modalData.name)
      document.body.style.overflow = ''
    },
    async configurableOptionCallback(variant) {
      const selectedConfiguration = Object.assign({ attribute_code: variant.type }, variant)
      await filterChangedProduct(selectedConfiguration, this.$store, this.$router)
      this.getQuantity();
    },
    async getQuantity() {
      if (this.stock.isLoading) return; // stock info is already loading
      this.stock.isLoading = true;
      try {
        const res = await this.$store.dispatch('stock/check', {
          product: this.getCurrentProduct,
          qty: this.getCurrentProduct.qty
        });
        this.manageQuantity = res.isManageStock;
        this.stock.max = res.isManageStock ? res.qty : null;
      } finally {
        this.stock.isLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.modal {
  &__content {
    max-height: 80vh;
  }
}
</style>
